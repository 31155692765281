
.button-file-input {
    background-color: var(--primary-color);
    border: none;
    color: var(--there-tertiary-text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    height: 25px;
    width: 150px;
    font-size: 10px;
    cursor: pointer;
}

.button-file-input:hover {
  background-color: var(--primary-secondary-color);
}

.button-file-input input[type="file"] {
  display: none;
}

.button-file-input label {
  cursor: pointer;
}

.button-file-input label:hover {
  background-color: var(--primary-secondary-color);
}

.button-file-input label:active {
  background-color: var(--primary-secondary-color);
}

.button-file-input label:focus {
  background-color: var(--primary-secondary-color);
}