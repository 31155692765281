.range-input {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 5px;
    background-color: var(--theme-background-color); /* Set the background color of the track to white */
    outline: none;
    border-radius: 5px; /* Adjust the value to control the roundness of the track */
    position: relative;
  
    /* Create the orange gradient for the left part of the track */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: var(--secondary-color); /* Set the color to orange or your desired shade */
      border-radius: 5px 0 0 5px; /* Adjust the border-radius to match the track */
    }
  
    /* Style the thumb (dragging knob) */
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 16px; /* Adjust the width to create a square knob */
      height: 16px; /* Adjust the height to create a square knob */
      background-color: var(--secondary-color); /* Change the color of the knob */
      /* border: none; */
      cursor: pointer;
      position: relative;
      z-index: 1; /* Ensure the knob is above the pseudo-element */
      border-radius: 24px;
    }
  
    &::-moz-range-thumb {
      width: 16px; /* Adjust the width to create a square knob */
      height: 16px; /* Adjust the height to create a square knob */
      background-color: var(--secondary-color); /* Change the color of the knob */
      border: none;
      border-radius: 0;
      cursor: pointer;
      position: relative;
      z-index: 1; /* Ensure the knob is above the pseudo-element */
    }
  
    /* Set the cursor to a pointer when hovering or dragging the knob */
    &:hover::-webkit-slider-thumb,
    &:active::-webkit-slider-thumb {
      cursor: pointer;
      background-color: var(--secondary-secondary-color);
    }
  }

.range-input-value {
    font-size: 10px;
    text-align: center;
    color: var(--theme-secondary-text-color);
    min-width: 40px;
    min-height: 25px;
    width: 40px;
    height: 25px;
    background: var(--theme-background-color);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 5px;
    border-radius: 10px;
    box-shadow: 0 2px 5px var(--theme-shadow-color);
    border: none;
}

.range-input-value:focus {
    outline: none;
}

/* Hide the default number input arrows in Chrome */
.range-input-value::-webkit-inner-spin-button,
.range-input-value::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the default number input arrows in Firefox */
.range-input-value[type=number] {
  -moz-appearance: textfield;
}