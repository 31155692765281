.accordion-container {
  width: 100%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  overflow-y: auto;
}

.tab-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 2px 5px var(--theme-shadow-color);
  box-sizing: border-box;
  color: var(--theme-secondary-text-color);
}

.tab-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: var(--theme-secondary-background-color);
  cursor: pointer;
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
}

.tab-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: var(--theme-secondary-background-color);
  box-shadow: 0 2px 5px var(--theme-shadow-color);
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
}

.content-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.tab-content.inactive {
  display: none;
}

.tab-content.active {
  display: flex;
}
