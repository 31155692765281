.button {
  background-color: var(--primary-color);
  border: none;
  color: var(--there-tertiary-text-color);
  text-align: center;
  display: inline-block;
  border-radius: 24px;
  height: 25px;
  width: 150px;
  font-size: 10px;
  cursor: pointer;
}

.button:hover {
  background-color: var(--primary-secondary-color);
}

.button label {
  cursor: pointer;
}

.button label:hover {
  background-color: var(--primary-secondary-color);
}

.button label:active {
  background-color: var(--primary-secondary-color);
}

.button label:focus {
  background-color: var(--primary-secondary-color);
}
