.texture-selector {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.preview-image-container {
    height: 50px;
    width: 50px;
}

.image-preview {
    width: 100%;
    height: 100%;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    box-sizing: border-box;
}