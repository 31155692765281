.loading-background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
.loading-animation {
    width: 50px;
    height: 50px;
    border: 5px solid #ccc;
    border-top-color: #555;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
}
  
@keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
}