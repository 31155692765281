/* Styles for the toggle container */
.toggle {
  width: 40px; /* Adjust the width of the toggle */
  height: 20px; /* Adjust the height of the toggle */
  border-radius: 15px; /* Adjust the border-radius to control the roundness of the toggle */
  background-color: var(--theme-background-color); /* Set the background color to white */
  position: relative;
  overflow: hidden;
    border: 1px solid var(--theme-secondary-border-color);
}

/* Styles for the toggle knob */
.toggle-label {
  display: block;
  width: 16px; /* Adjust the width of the knob */
  height: 16px; /* Adjust the height of the knob */
  background-color: var(--secondary-color); /* Set the color of the knob to orange */
  border-radius: 15px; /* Match the border-radius of the toggle to create rounded sides */
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease; /* Add smooth transition for the sliding effect */
  cursor: pointer;
}

.toggle-label:hover {
  background-color: var(--secondary-secondary-color); /* Change the color of the knob when hovering */
}

/* Styles for the toggle checkbox */
.toggle-checkbox {
  display: none;
}

/* Styles for the checked state of the toggle */
.toggle-checkbox:checked + .toggle-label {
  transform: translateX(100%); /* Slide the knob to the right */
}
