.form-field-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.form-field-label {
    font-size: 12px;
    color: var(--theme-secondary-text-color);
    font-weight: bold;
    margin: 5px;
    min-width: fit-content;
    width: 30%;
}

.form-field-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-left: 5px;
    min-width: 140px;
    display: none;
}

.form-field-content {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}