.logo {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    /* padding: 130px 100px 130px 100px; */
    overflow-y: auto;
    overflow-x: hidden;
}

.container-vertical {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 55%;
    flex-shrink: 0;
    /* background-color: #acac31; */
    padding: 25px;
    box-sizing: border-box;
}

.left-container-vertical {
  width: 100%;
  height: 80%;
}

.right-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100%;
    width: 45%;
    /* background-color: #ac76ac; */
    padding: 25px;
    box-sizing: border-box;
}

.right-container-vertical {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
}

.confirm-button-container {
  padding-top: 25px;
}

.canvas-container {
  width: 100%;
  height: 100%;
  /* background-color: brown; */
}

.canvas-container canvas {
  width: 100%;
  height: 100%;
}

.drop-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  /* position: relative; */
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}