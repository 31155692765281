:root {
  --primary-color: #01015A;
  --primary-secondary-color: #242473;
  --secondary-color: #ff9a3c;
  --secondary-secondary-color: #ff7c00;
  --theme-background-color: #ffffff;
  --theme-secondary-background-color: #f5f5f5;
  --theme-text-color: #000000;
  --theme-secondary-text-color: #1c1c1c;
  --there-tertiary-text-color: #ffffff;
  --theme-border-color: #e0e0e0;
  --theme-secondary-border-color: #1c1c1c;
  --theme-shadow-color: rgba(0, 0, 0, 0.2);
  --theme-secondary-shadow-color: rgba(0, 0, 0, 0.5);
  --theme-link-color: #0000ee;
  --theme-link-hover-color: #0000ee;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--theme-background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Define CSS variables for scrollbar customization */
:root {
  --scrollbar-width: 8px;
  --scrollbar-color: var(--theme-shadow-color);
  --scrollbar-thumb-color: var(--theme-secondary-shadow-color);
}

/* Apply scrollbar styles to all elements */
*::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

*::-webkit-scrollbar-track {
  background-color: var(--scrollbar-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 4px;
}

*::-moz-scrollbar {
  width: var(--scrollbar-width);
}

*::-moz-scrollbar-track {
  background-color: var(--scrollbar-color);
}

*::-moz-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 4px;
}

/* Example usage */
body {
  overflow-y: scroll; /* Enable vertical scrollbar on the body */
}
