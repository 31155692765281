/* Styles for the color picker container */
.color-picker {
    width: 75px; /* Adjust the width of the color picker */
    height: 25px; /* Adjust the height of the color picker */
    border-radius: 24px; /* Adjust the border-radius to control the roundness of the color picker */
    overflow: hidden;
}
  
/* Styles for the color picker input */
.color-picker-input {
    width: 200%;
    height: 200%;
    padding: 0;
    border: none;
    cursor: pointer;
    position: relative;
    top: -50%;
    left: -50%;
}