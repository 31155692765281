/* Styles for the dropdown container */
.dropdown {
    display: inline-block;
    position: relative;
    border: 1px solid var(--theme-border-color);
    background-color: var(--theme-background-color);
    border-radius: 24px;
    overflow: hidden;
    width: 100%;
}
  
/* Styles for the dropdown toggle */
.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    width: 95%;
    box-sizing: border-box;
}

/* Styles for the dropdown icon */
.dropdown-icon {
    margin-left: 8px;
}

.dropdown-menu-container {
    position: fixed;
    top: inherit;
    left: inherit;
    z-index: 9999; /* Ensure the dropdown menu is on top of other elements */
    overflow: hidden;
    width: max-content;
    max-height: 65vh;
    border: 1px solid var(--theme-border-color);
    border-top: none;
    border-radius: 24px;
    box-shadow: 0 2px 4px var(--theme-shadow-color);
    display: none;
}

/* Styles for the dropdown menu */
.dropdown-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: var(--theme-background-color);
    width: max-content;
    overflow-y: auto;
    height: 65vh;
}

/* Styles for the dropdown options */
.dropdown-option {
    padding: 10px;
    cursor: pointer;
}

/* Styles for the selected value */
.dropdown-selected-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
